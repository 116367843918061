<template>
  <div class="useral-edit-sale-addon">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <div>
          <div class="general-fields">
            <custom-validate-input :label="$t('sales.addons.labels.name')"
                                   :classes="{'w-full': true}"
                                   align="right"
                                   rtl
                                   :invalid-text="$t('sales.addons.invalidTexts.name')"
                                   :regex="nameRegex"
                                   v-model="saleAddon.name"/>

            <custom-number-input :label="$t('sales.addons.labels.maxSelect')"
                                :classes="{'w-full': true}"
                                 :is-empty="true"
                                align="right"
                                v-model="saleAddon.maxSelect"/>
          </div>

          <vs-row class="select-character-section">
            <span class="title">{{ $t('sales.addons.labels.priceBaseCharacter') }}</span>
            <vs-row>
              <vs-col>
                <div class="character-wrapper"
                     v-for="(character, character_index) in charactersList"
                     :key="character_index">

                  <edit-sale-addon-character @character:deleted="deleteSaleAddonCharacter(character, character_index)"
                                             v-model="charactersList[character_index]"/>
                </div>
              </vs-col>
            </vs-row>
          </vs-row>
        </div>

        <vs-button id="deleteUserBTN"
                   class="w-full mt-3"
                   color="danger"
                   v-if="checkUserPermissions('sale_addon.delete')"
                   @click="$refs.deleteConfirmation.showDialog()">
          {{ $t('sales.addons.labels.delete') }}
        </vs-button>

        <custom-dialog ref="deleteConfirmation"
                       :title="$t('sales.addons.confirmations.delete.title')"
                       :body="$t('sales.addons.confirmations.delete.body', {name: saleAddon.name.value})"
                       @accept="deleteSaleAddon"/>
      </div>
    </div>

    <vs-button v-show="false"
               id="editSaleAddonBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {getCharacters} from "@/http/requests/characters";
  import {checkUserPermissions} from '@/assets/js/functions'
  import {getSaleAddon, editSaleAddon, deleteSaleAddon} from "@/http/requests/salesAddons"
  import CustomDialog from '@/components/customDialog/customDialog'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import CustomNumberInput from "@/components/customInput/customNumberInput";
  import EditSaleAddonCharacter from "./editSaleAddonCharacter";

  export default {
    name: 'EditSaleAddon',
    components: {
      CustomNumberInput,
      EditSaleAddonCharacter,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      saleAddonId: 0
    },
    data() {
      return {
        nameRegex: this.$validator('regex.sale.addon.name'),
        characters: [
          {
            id: null,
            label: this.$t('sales.addons.labels.all')
          }
        ],
        saleAddon: {
          name: {
            value: '',
            isValid: false
          },
          maxSelect: {
            value: '',
            isValid: true
          },
          character: {
            id: null
          }
        },
        charactersList: [],
        attributesSelectedValues: {},
        saleAddonCharacterValues: [],
      }
    },
    created() {
      setTimeout(() => {
        if (checkUserPermissions('character.show')) {
          this.getCharacters()
        }
      }, 2000)
      this.getSaleAddon()
    },
    methods: {
      getSaleAddon() {
        getSaleAddon(this.saleAddonId).then(response => {
          const saleAddon = response.data.data
          this.saleAddon.name = {
            value: saleAddon.name,
            isValid: true
          }
          this.saleAddon.maxSelect = {
            value: parseInt(saleAddon.max_select) === 100 ? '' : saleAddon.max_select,
            isValid: true
          }
          if (saleAddon.characters.length > 0) {
            saleAddon.characters.forEach(item => {
              const character = {
                id: 'new',
                price: {
                  value: item.price.toString(),
                  isValid: true
                },
                required: item.required,
                character: {
                  id: item.id ? item.id : 0,
                  name: item.name ? item.name : this.$t('sales.addons.labels.all')
                }
              }

              const characterIndex = this.charactersList.map(e => e.character.id).indexOf(item.id)
              if (characterIndex > -1) {
                this.charactersList[characterIndex] = character
              } else {
                this.charactersList.push(character)
              }
            })
          }
        })
      },
      getCharacters() {
        getCharacters().then(response => {
          const characters = response.data.data
          this.saleAddonCharacterValues = []
          characters.forEach(character => {
            this.characters.push({
              id: character.id,
              label: character.name
            })
            const characterIndex = this.charactersList.map(e => e.character.id).indexOf(character.id)
            if (characterIndex === -1) {
              this.saleAddonCharacterValues.push({
                label: character.name,
                value: {
                  id: character.id,
                  name: character.name,
                  value: character.id
                }
              })
              this.charactersList.push({
                id: 'new',
                price: {
                  value: '',
                  isValid: true
                },
                required: character.required,
                character: {
                  id: character.id ? character.id : 0,
                  name: character.name ? character.name : this.$t('sales.addons.labels.all')
                }
                // variables: [variable]
              })
            }
          })
        })
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (!this.saleAddon.name.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.addons.validators.name'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        } else if (!this.saleAddon.maxSelect.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.addons.validators.maxSelect'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        } else if (this.charactersList.length === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.addons.validators.noCharacter'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        let characters = []
        this.charactersList.forEach(item => {
          if (item.price.value.length > 0) {
            if (item.price.isValid) {
              characters.push({
                character_id: item.character.id > 0 ? item.character.id : null,
                price: item.price.value,
                required: item.required ? 1 : 0
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('sales.addons.validators.price', {name: item.character.name}),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'danger'
              })
              return false
            }
          }
        })

        const saleAddon = {
          name: this.saleAddon.name.value,
          max_select: this.saleAddon.maxSelect.value.length > 0 ? this.saleAddon.maxSelect.value : '100',
          characters: characters
        }

        if (checkUserPermissions(['character.show', 'user.set_character']) === 2) saleAddon.character_id = this.saleAddon.character.id

        editSaleAddon(this.saleAddonId, saleAddon).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.addons.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('edited')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': this.$t('sales.addons.notifications.parseError.name'),
              'price': this.$t('sales.addons.notifications.parseError.price'),
              'character_id': this.$t('sales.addons.notifications.parseError.character'),
              'max_select': this.$t('sales.addons.notifications.parseError.maxSelect')
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.addons.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      },
      deleteSaleAddonCharacter(character, index) {
        this.charactersList.splice(index, 1)
      },

      deleteSaleAddon() {
        deleteSaleAddon(this.saleAddonId || this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.addons.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
          this.$emit('edited')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            switch (error.response.status) {
              case 409:
                if (error.response.data.message === 'restrict') {
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.addons.notifications.addonIsUsed'),
                    color: 'danger',
                    time: 2400,
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                }
                break
              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.addons.notifications.delete.error'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-edit-sale-addon {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .general-fields {
          margin-bottom: 20px;
        }

        .custom-profile-image-input {
          .profile-image-label {
            height: 110px;
            width: 110px;
          }
        }

        .date-picker-item {
          border: 0 !important;
          border-radius: 0.4rem;
          position: relative;

          .date-picker-label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 5px;
            padding: 0 5px;
            background-color: #ffffff;
            z-index: 1;
          }

          .vpd-input-group {
            width: 20px !important;
            position: absolute;
            top: 6px;
            left: 2px;

            .vpd-icon-btn {
              opacity: 1 !important;
              height: 25px !important;
              width: 20px !important;
              background-color: inherit !important;

              svg {
                fill: #000000;
                overflow: visible !important;
              }
            }

            .form-control {
              border: 0;
              width: 100%;
              display: none;
              line-height: 35px;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;
          flex-wrap: wrap;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            width: max-content;
            direction: rtl;

            label {
              direction: ltr;
              margin-left: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.character-input {
            justify-content: space-between;

            .radio-item {
              justify-content: flex-end;
              width: 33%;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .select-character-section {
          margin-top: 30px;
          padding: 30px 5px 0;
          border: 1px solid #cdcdcd;
          border-radius: 0.5rem;
          position: relative;

          .title {
            position: absolute;
            top: -11px;
            left: 5px;
            padding: 0 5px;
            backdrop-filter: sepia(1);
          }

          .character-wrapper:last-of-type {
            .edit-sale-addon-character-box {
              margin-bottom: 5px;
            }
          }
        }

        .insert-attribute-btn {
          font-size: 18px;
          position: absolute;
          right: 3px;
          top: -10px;
          bottom: 0;
          margin: auto;
          height: fit-content;
          padding: 6px;
          border-radius: .5rem;
          border: 1px solid #cecece;
          background: #cecece54;
          cursor: pointer;
          z-index: 200;
          transition: all .3s ease;

          &:hover {
            box-shadow: 0 0 10px #cecece;
          }
        }

        .vs-divider {
          margin-bottom: 20px;

          .vs-divider-border.after {
            background-color: unset;
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
